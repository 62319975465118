<template>
  <SuiModal basic v-model="dialog">
    <SuiModalContent >
      <div class="modal-container">
        <a class="close-icon" @click="close">
            <i class="times icon general-blue-color"></i>
          </a>
          <SuiModalDescription>
            <div class="align-items-center div-form">
              <h2
                class="general-blue-color"
                style="margin-top: -16px; margin-bottom: 30px; font-size: 17px"
              >{{$t('residents.uploadResidents')}}</h2>

              <div v-if="!showResults" class="ui form">
                <div class="row mt-3">
                  <div class="col-md-12 pt-2">
                    <label>
                      {{$t('residents.chooseExcel')}}<br><br>{{$t('residents.chooseExcel1')}}
                      <a :href="templateUploadResidents">{{$t('residents.downloadExcel')}}</a>.
                    </label>
                  </div>
                </div>

                <div class="row mt-5">
                  <div class="col-md-12 ui input text-center" style="justify-content: center; padding-left: 27px">
                    <FileUploader
                      :maxNumFiles="1"
                      :maxFileSizeInMB="10"
                      :allowedFileTypes="['xls', 'xlsx']"
                      :sizeOfButton="{'height': '30px', 'width': '140px', 'justify-content': 'center'}"
                      :modelValue="[]"
                      @update-modelValue="loadFile"
                    />
                  </div>
                </div>
              </div>

              <div v-else>
                <div v-if="!isLoading && loadedFileName" class="mt-3">
                  <span>{{$t('residents.fileUploaded')}}: </span>
                  <span style="font-size: 17px"><strong>{{ loadedFileName }}</strong></span>
                </div>

                <div v-if="!isLoading && errorStructure.length" class="mt-2">
                  <div class="ui red message">
                    <div class="header">{{$t('residents.wrongExcel')}}</div>
                    <p>{{$t('residents.wrongColumns')}}:</p>
                    <p><strong>{{ errorStructure.join(', ') }}</strong></p>
                  </div>
                </div>

                <div v-if="errors.length || saved.length">
                  <span class="ui red text"><strong>{{ errors.length }}</strong> {{$t('residents.wrongRegisters')}}</span>
                  <span class="ui success text" style="margin-left: 20px"><strong>{{ saved.length }}</strong> {{$t('residents.loadedResidents')}}</span>
                </div>

                <div class="mt-1">
                  <div v-if="errors.length" class="ui red message" style="overflow-y: auto; max-height: 380px">
                    <div class="header">{{$t('residents.notUploaded')}}:</div>
                    <ul class="list">
                      <li
                        v-for="error in errors"
                        :key="error.index"
                      >
                        {{ $t('residents.row') }}: <strong>{{ error.index + 2 }}</strong>,
                        {{ $t('residents.resident') }}:
                        <strong>
                          <span v-if="error.row.nombres">{{ error.row.nombres }}</span>
                          <span v-if="error.row.apellidos">&nbsp;{{ error.row.apellidos }}</span>
                        </strong>.
                        {{ $t('residents.fieldWithError') }}: <strong>{{ error.emptyFields.join(', ') }}</strong>
                      </li>
                    </ul>
                  </div>

                  <!-- <div v-if="saved.length" class="ui success message" style="overflow-y: auto; max-height: 140px">
                    <div class="header">{{$t('residents.createdResidents')}}:</div>
                    <ul class="list">
                      <li
                        v-for="row in saved"
                        :key="row.index"
                      >
                        {{$t('residents.row')}}: <strong>{{ row.index + 2 }}</strong>,
                        {{$t('residents.resident')}}: <strong>{{ row.row.nombres }} {{ row.row.apellidos }}</strong>.
                      </li>
                    </ul>
                  </div> -->
                </div>
              </div>

            </div>
          </SuiModalDescription>
      </div>
    </SuiModalContent>
  </SuiModal>
</template>

<script>
import {
  SuiModal,
  SuiModalContent,
  SuiModalDescription
} from 'vue-fomantic-ui'
import { mapGetters, mapActions } from 'vuex'
import FileUploader from '../../components/form/FileUploader'
import { uploadResidents } from '../../api/user'

export default {
  name: 'ResidentUpload',
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription,
    FileUploader
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      showResults: false,
      loadedFileName: '',
      errorStructure: [],
      errors: [],
      saved: []
    }
  },
  computed: {
    ...mapGetters('residential', ['activeResidential']),
    dialog () {
      return this.show
    }
  },
  methods: {
    ...mapActions('user', ['loadResidents']),
    ...mapActions('loading', ['setLoading']),

    loadFile (files) {
      const file = files[0]
      this.loadedFileName = file.name

      const formData = new FormData()
      formData.append('file', file)
      formData.append('residentialId', this.activeResidential.residentialId)
      this.setLoading(true)
      this.errorStructure = []
      this.errors = []
      this.saved = []

      uploadResidents(formData).then(({ data }) => {
        this.saved = data.saved
        this.errors = data.errors
        this.loadResidents(this.activeResidential.residentialId)
      }).catch(({ response: { data } }) => {
        if (data.code === '001') {
          this.errorStructure = data.notFoundFields
        } else {
          this.$swal(this.$t('errors.tryLater'), '', 'error')
        }
      }).finally(() => {
        this.setLoading(false)
        this.showResults = true
      })
    },

    clearForm () {
      this.loadedFileName = ''
      this.errorStructure = []
      this.errors = []
      this.saved = []
      this.showResults = false
    },

    close () {
      this.clearForm()
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
  .modal-container {
    background-color: #fff;
    color: black;
    width: 655px;
    border-radius: 20px;
    padding: 20px;
    padding-top: 10px;
    webkit-box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 41%);
    box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 41%);
    z-index: 99;
  }

  .uploader_field {
    justify-content: center !important;
  }
</style>
