<template>
  <div class="justify-content-start h-100 view-container">
    <HeaderResidential />
    <div class="mt-4">
      <div class="row">
        <div class="col-md-4 pt-2">
          <Button
            :text="$t('residents.create')"
            color="primary"
            class="d-inline"
            @clicked="newResident"
          />
          <Button
            :text="$t('residents.uploadXls')"
            color="primary"
            class="d-inline"
            icon="upload"
            @clicked="showUpload = true"
          />
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-3">
          <Select
            v-model="filters.statusSelected"
            :options="statusList"
            :search="false"
            :multiple="false"
            :clearable="true"
            :placeholder="$t('residents.filterByState')"
          />
        </div>
        <div class="col-md-2">
          <div class="ui icon input" style="width: 99%">
            <input type="text" v-model="filters.text" :placeholder="$t('search')">
            <i class="search icon"></i>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-5" style="margin-left: 12px; margin-right: 13px">
        <div class="row">
          <table class="ui blue unstackable selectable celled table" style="cursor: default !important; color: #808080">
            <thead>
            <tr>
              <th>{{$t('residents.resident')}}</th>
              <th style="width: 7%" class="text-center">{{$t('residents.message')}}</th>
              <th style="width: 7%" class="text-center">{{$t('residents.edit')}}</th>
              <th style="width: 7%" class="text-center">{{$t('residents.state')}}</th>
              <th style="width: 10%" class="text-center">{{$t('building.building')}}</th>
              <th style="width: 10%" class="text-center">{{$t('building.apartment')}}</th>
              <th style="width: 10%" class="text-center">{{$t('residents.register')}}</th>
              <th style="width: 10%" class="text-center">{{$t('residents.enable')}}</th>
            </tr>
            </thead>
            <tbody v-if="residentsOrdered.length === 0">
              <tr>
                <td colspan="8" class="text-center">
                  {{$t('residents.noResidents')}}
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
                v-for="resident in residentsOrdered"
                :key="resident"
              >
                <td @click="editResident(resident)">
                  <h4 class="ui image header">
                    <img :src="getAvatar(resident.avatar)" class="ui mini rounded image">
                    <div class="content">{{ resident.firstName }} {{ resident.lastName }}</div>
                  </h4>
                </td>
                <td class="text-center">
                  <router-link :to="{
                    path: '/messaging',
                    }"
                    :data-tooltip="$t('messages.sendMessage')"
                    data-position="bottom center"
                    style="color: var(--messaging-color)"
                    @click.prevent="sendMessage(resident)"
                  >
                    <i class="envelope icon"></i>
                  </router-link>
                </td>
                <td class="text-center">
                  <a href="javascript:;" @click="editResident(resident)" :data-tooltip="$t('residents.editResident')" data-position="bottom center">
                    <i class="pencil alternate icon"></i>
                  </a>
                </td>
                <td style="text-align: center">
                  <div class="ui toggle checkbox text-center">
                    <input
                      type="checkbox"
                      :checked="resident.status === status.ACTIVE"
                      @change="changeStatus(resident.userResidentialId, $event)"
                    >
                    <label style="margin-left: -6px"></label>
                  </div>
                  <br>
                  <small>{{ $t(`status.${resident.status}`) }}</small>
                </td>
                <td @click="editResident(resident)" class="text-center">{{ resident.building }}</td>
                <td @click="editResident(resident)" class="text-center">{{ resident.apartment }}</td>
                <td @click="editResident(resident)" class="text-center">{{ formatDate(resident.createdAt) }}</td>
                <td @click="editResident(resident)" v-if="!resident.activedAt" style="text-align: center" class="red">
                  <i class="exclamation triangle icon"></i><br><small>{{ $t('status.PENDING_ACTIVATION') }}</small>
                </td>
                <td v-else @click="editResident(resident)" class="text-center">{{ formatDate(resident.activedAt) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <ResidentForm
    :show="showResidentForm"
    :data="residentForm"
    @close="closeForm"
  />

  <ResidentUpload
    :show="showResidentUpload"
    @close="closeUpload"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import HeaderResidential from '../../components/ui/HeaderResidential'
import Button from '../../components/form/Button'
import Select from '../../components/form/Select'
import ResidentForm from './ResidentForm'
import ResidentUpload from './ResidentUpload'
import helpers from '../../utils/helpers'
import globals from '../../utils/globals'

export default {
  name: 'Resident',
  components: {
    HeaderResidential,
    Button,
    Select,
    ResidentForm,
    ResidentUpload
  },
  data () {
    return {
      showForm: false,
      showUpload: false,
      residentEdition: {},
      statusList: [
        { id: globals.status.ACTIVE, description: this.$t('status.ACTIVE') },
        { id: globals.status.INACTIVE, description: this.$t('status.INACTIVE') },
        { id: globals.status.PENDING_ACTIVATION, description: this.$t('status.PENDING_ACTIVATION') }
      ],
      filters: {
        text: '',
        statusSelected: ''
      }
    }
  },
  computed: {
    ...mapGetters('user', ['residents']),
    ...mapGetters('residential', ['activeResidential']),
    ...mapGetters('messaging', ['residentialMembersToMessage']),
    ...mapGetters('routes', ['fromRoute', 'toRoute']),

    residentsOrdered () {
      let residents = this.residents

      if (this.filters.text) {
        residents = residents.filter(resident => {
          const firstName = resident.firstName ?? ''
          const lastName = resident.lastName ?? ''
          const apartment = resident.apartment ?? ''
          const email = resident.email ?? ''
          const name = `${firstName.toLowerCase()} ${lastName.toLowerCase()}`
          return name.includes(this.filters.text.toLowerCase()) ||
            apartment.toLowerCase().includes(this.filters.text.toLowerCase()) ||
            email.toLowerCase().includes(this.filters.text.toLowerCase())
        })
      }
      if (this.filters.statusSelected) {
        const filterStatusSelected = this.filters.statusSelected.value
        residents = residents.filter(resident => {
          if (filterStatusSelected === this.status.PENDING_ACTIVATION) {
            return resident.status === this.status.INACTIVE && resident.activedAt === null
          } else {
            return resident.status === filterStatusSelected
          }
        })
      }

      const pending = residents.filter(x => x.activedAt === null).sort((a, b) => this.sortByName(a, b))
      const notPending = residents.filter(x => x.activedAt !== null).sort((a, b) => this.sortByName(a, b))
      return pending.concat(notPending)
    },

    showResidentForm () {
      return this.showForm
    },

    showResidentUpload () {
      return this.showUpload
    },

    residentForm () {
      return this.residentEdition
    }
  },
  created () {
    if (this.residents.length === 0) {
      this.loadResidents(this.activeResidential.residentialId)
    }
  },
  beforeUnmount () {
    if (!['/messaging'].includes(this.toRoute)) {
      this.$store.commit('messaging/SET_RESIDENTIAL_MEMBERS_TO_MESSAGE', [])
    }
  },
  methods: {
    ...mapActions('user', ['loadResidents', 'changeStatusResident']),

    ...mapActions('loading', ['setLoading']),

    ...mapActions('messaging', ['setResidentialMembersToMessage']),

    sendMessage (resident) {
      // this.setResidentsToMessage([resident])
      this.$store.dispatch('messaging/setResidentialMembersToMessage', [resident])
    },

    formatDate (date) {
      return helpers.formatDateDDMMYYServer(date)
    },

    getAvatar (avatar) {
      return avatar || globals.defaultAvatar
    },

    sortByName (a, b) {
      const aName = (a.firstName + ' ' + a.lastName).toLowerCase()
      const bName = (b.firstName + ' ' + b.lastName).toLowerCase()
      if (aName < bName) {
        return -1
      }
      if (aName > bName) {
        return 1
      }
      return 0
    },

    changeStatus (userResidentialId, e) {
      const isActive = e.target.checked
      this.setLoading(true)
      this.changeStatusResident({ userResidentialId, isActive })
        .then(() => {})
        .catch((err) => {
          console.log(err)
          this.$swal(this.$t('errors.somethingHappened'), '', 'error')
        })
        .finally(() => {
          this.setLoading(false)
        })
    },

    newResident () {
      this.residentEdition = {}
      this.showForm = true
    },

    editResident (resident) {
      this.residentEdition = resident
      this.showForm = true
    },

    closeForm () {
      this.showForm = false
    },

    closeUpload () {
      this.showUpload = false
    }
  }
}
</script>

<style scoped>
  .ui.table {
    background: transparent;
  }

  .ui.table > thead > tr > th {
    color: #808080
  }

  .ui.image.header > .content {
    font-size: 14px;
    font-weight: normal;
  }
</style>
