<template>
  <SuiModal basic v-model="dialog">
    <SuiModalContent >
      <div class="modal-container">
        <a @click="close" class="close-icon" style="left: 96%">
            <i class="times icon general-blue-color"></i>
          </a>
          <SuiModalDescription>
            <div class="align-items-center div-form ">
              <div style="display: flex; ">
                <h2
                  class="general-blue-color"
                  style="margin-top: -2px; margin-right: 30px; font-size: 17px"
                >{{$t('residents.resident')}}</h2>
                {{$t('status.INACTIVE')}}
                <div
                  class="ui toggle checkbox text-center"
                  style="margin-left: 10px; margin-right: 16px; padding-top: 4px;"
                >
                  <input type="checkbox" v-model="residentForm.isActive">
                  <label style="height: 0px; width:0px"></label>
                </div>
                 {{$t('status.ACTIVE')}}
              </div>
              <div class="ui form">
                <form autocomplete="off">
                  <div class="row mb-1">
                    <div class="col-md-3 pt-2"><label>{{$t('residents.names')}}</label></div>
                    <div class="col ui input">
                      <input
                        type="text"
                        :maxlength="maxLengthFirstName"
                        v-model="residentForm.firstName" :readonly="isEditing">
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-md-3 pt-2"><label>{{$t('residents.lastNames')}}</label></div>
                    <div class="col ui input">
                      <input
                        type="text"
                        :maxlength="maxLengthLastName"
                        v-model="residentForm.lastName" :readonly="isEditing">
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-md-3 pt-2"><label>{{$t('residents.phone')}}</label></div>
                    <div class="col-md-2 pt-2" v-show="isEditing">
                      <label>
                        {{ countryCodeSelected.text }}
                      </label>
                    </div>
                    <div class="col-md-3 ui input" v-show="!isEditing">
                      <Select
                        v-show="!isEditing"
                        id="countryCodes"
                        v-model="countryCodeSelected"
                        :search="true"
                        :placeholder="$t('residents.code')"
                        :options="countriesCodeInfoToShow"
                        :selection="true"
                        :fluid="false"
                      />
                    </div>
                    <div class="col ui input">
                      <input
                        type="text"
                        v-model="residentForm.phoneNumber"
                        :readonly="isEditing"
                        :maxlength="maxLengthPhoneNumber"
                      >
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-md-3 pt-2"><label>{{$t('residents.email')}}</label></div>
                    <div class="col ui input">
                      <input
                        type="text"
                        v-model="residentForm.email"
                        :maxlength="maxLengthEmail"
                        :readonly="isEditing"
                        style="text-transform: lowercase"
                        @change="residentForm.email = residentForm.email.toLowerCase()"
                      >
                    </div>
                  </div>
                  <div class="row mb-1">
                    <div class="col-md-3 pt-2"><label>{{$t('building.building')}}</label></div>
                    <div class="col-md-3 ui input">
                      <input
                        type="text"
                        :maxlength="maxLengthBuilding"
                        :readonly="true"
                        v-model="residentForm.building"
                        >
                    </div>
                    <div class="col-md-2 pt-2"><label>{{$t('building.apartment')}}</label></div>
                    <div class="col-md-4 ui input">
                      <input
                        type="text"
                        :maxlength="maxLengthApartment"
                        v-model="residentForm.apartment">
                    </div>
                  </div>
                <div class="row mb-1" v-if="isEditing" >
                    <div class="col ui input pt-2">
                      {{$t('residents.proprietary')}}
                      <div
                        class="ui toggle checkbox text-center"
                        style="margin-left: 10px; margin-right: 16px; padding-top: 4px;"
                      >
                        <input
                          type="checkbox"
                          :checked="this.changeOwnerRole"
                          @change="toggleRole('OWNER', $event)"
                        >
                        <label style="height: 0px; width:0px"></label>
                      </div>
                    </div>
                    <div class="col ui input pt-2">
                       {{$t('residents.resident')}}
                      <div
                        class="ui toggle checkbox text-center"
                        style="margin-left: 10px; margin-right: 16px; padding-top: 4px;"
                      >
                        <input
                          type="checkbox"
                          :checked="this.changeOccupierRole"
                          @change="toggleRole('OCCUPIER', $event)"
                        >
                        <label style="height: 0px; width:0px"></label>
                      </div>
                    </div>
                    <div class="col ui input pt-2">
                      {{$t('residents.board')}}
                      <div
                        class="ui toggle checkbox text-center"
                        style="margin-left: 10px; margin-right: 16px; padding-top: 4px;"
                      >
                        <input
                          type="checkbox"
                          :checked="this.changeCouncilRole"
                          @change="toggleRole('COUNCIL', $event)"
                        >
                        <label style="height: 0px; width:0px"></label>
                      </div>
                    </div>
                    <div class="col ui input pt-2">
                       {{$t('residents.comitee')}}
                      <div
                        class="ui toggle checkbox text-center"
                        style="margin-left: 10px; margin-right: 16px; padding-top: 4px;"
                      >
                        <input
                          type="checkbox"
                          :checked="this.changeCohabitationRole"
                          @change="toggleRole('COHABITATION', $event)"
                        >
                        <label style="height: 0px; width:0px"></label>
                      </div>
                    </div>
                    <div class="col ui input pt-2">
                       {{$t('residents.tenant')}}
                      <div
                        class="ui toggle checkbox text-center"
                        style="margin-left: 10px; margin-right: 16px; padding-top: 4px;"
                      >
                        <input
                          type="checkbox"
                          :checked="this.changeTenantRole"
                          @change="toggleRole('TENANT', $event)"
                        >
                        <label style="height: 0px; width:0px"></label>
                      </div>
                    </div>
                  </div>
                </form>

                <div
                  class="errorMessageValidation"
                 v-if="v$.residentForm.$errors.length">
                  <!-- <p
                    v-for="(error, index) in v$.residentForm.$errors"
                    :key="index"
                    class="error text-center mb-1"
                  >{{ error.$message }}</p> -->
                  <p
                    class="text-danger"
                  >{{ v$.residentForm.$errors[0].$message }}</p>
                </div>

                <!-- mt-5 -->
                <div
                  class="text-center mb-4"
                  :style="v$.residentForm.$errors.length?'margin-top: 1rem !important':'margin-top: 3rem !important'"
                >
                  <div v-if="isEditing">
                    <Button
                      :text="$t('residents.deleteResident')"
                      @clicked="remove"
                      color="third-color"
                      class="d-inline"
                      :appearance="{ height: '29px' }"
                    />
                    <Button
                      :text="$t('buttons.modified')"
                      @clicked="save"
                      color="primary"
                      class="d-inline"
                      style="margin-left: 20px"
                    />
                  </div>
                  <div v-else>
                    <Button :text="$t('buttons.create')" @clicked="save" color="primary" class="d-inline" />
                  </div>
                </div>
              </div>
            </div>
          </SuiModalDescription>
      </div>
    </SuiModalContent>
  </SuiModal>
</template>

<script>
import {
  SuiModal,
  SuiModalContent,
  SuiModalDescription
} from 'vue-fomantic-ui'
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, minLength, maxLength, email, numeric } from '@vuelidate/validators/dist/raw.esm'
import { mapActions, mapGetters } from 'vuex'
import Button from '../../components/form/Button'
import Select from '../../components/form/Select'
import countryCodes from '../../utils/countryCodes'
import helper from '@/utils/helpers'
import { RolesEnum } from '@/utils/roles.enum.js'

export default {
  name: 'ResidentForm',
  components: {
    SuiModal,
    SuiModalContent,
    SuiModalDescription,
    Select,
    Button
  },
  props: {
    show: {
      type: Boolean,
      required: false,
      default: false
    },
    data: Object
  },
  watch: {
    show () {
      if (this.show) {
        const { firstName, lastName, countryCode, phoneNumber, phoneCountryCode, email, apartment, status } = this.data
        this.isEditing = !!this.data.id
        this.residentForm = {
          firstName,
          lastName,
          countryCode,
          phoneCountryCode,
          phoneNumber,
          email,
          apartment,
          isActive: status === this.status.ACTIVE
        }
        // building automatic
        this.residentForm.building = this.activeResidential.name
        if (this.isEditing) {
          this.countryCodeSelected = {
            value: countryCode,
            text: `${countryCode} (+${phoneCountryCode})`,
            flag: countryCode
          }
        }
        console.log('isEditing', this.isEditing)
        if (this.isEditing) {
          this.getRoles(this.activeResidential.residentialId, this.data.id)
        }
      }
    },
    countryCodeSelected ({ value }) {
      if (value !== '') {
        this.residentForm.phoneCountryCode = countryCodes.countryCallCodes[value]
        this.residentForm.countryCode = value
      }
    }
  },
  data () {
    return {
      isEditing: false,
      countryCodeSelected: '',
      residentForm: {
        firstName: '',
        lastName: '',
        countryCode: '',
        phoneCountryCode: '',
        phoneNumber: '',
        email: '',
        building: '',
        apartment: '',
        isActive: false
      },
      changeOwnerRole: false,
      changeOccupierRole: false,
      changeCouncilRole: false,
      changeCohabitationRole: false,
      changeTenantRole: false,
      maxLengthPhoneNumber: 10,
      minLengthPhoneNumber: 5,
      maxLengthFirstName: 20,
      maxLengthLastName: 20,
      maxLengthBuilding: 25,
      maxLengthApartment: 4,
      maxLengthEmail: 100
    }
  },
  mounted () { },
  setup: () => ({ v$: useVuelidate() }),
  validations () {
    return {
      residentForm: {
        firstName: {
          required: helpers.withMessage(this.$t('errors.residents.nameRequired'), required),
          format: helpers.withMessage(this.$t('errors.residents.nameLetters'), helper.validateFLNameFormat),
          maxLengthValue: helpers.withMessage(`${this.$t('errors.residents.nameMax')} ${this.maxLengthFirstName} ${this.$t('errors.residents.characters')}`, maxLength(this.maxLengthFirstName))
        },
        lastName: {
          required: helpers.withMessage(this.$t('errors.residents.lastNameRequired'), required),
          format: helpers.withMessage(this.$t('errors.residents.lastNameLetters'), helper.validateFLNameFormat),
          maxLengthValue: helpers.withMessage(`${this.$t('errors.residents.lastNameMax')} ${this.maxLengthLastName} ${this.$t('errors.residents.characters')}`, maxLength(this.maxLengthLastName))
        },
        countryCode: {
          required: helpers.withMessage(this.$t('errors.residents.codeRequired'), required)
        },
        phoneNumber: {
          required: helpers.withMessage(this.$t('errors.residents.phoneRequired'), required),
          numeric: helpers.withMessage(this.$t('errors.residents.validPhone'), numeric),
          minLengthValue: helpers.withMessage(`${this.$t('errors.residents.phoneMin')} ${this.minLengthPhoneNumber} ${this.$t('errors.residents.digits')}`, minLength(this.minLengthPhoneNumber)),
          maxLengthValue: helpers.withMessage(`${this.$t('errors.residents.phoneMax')} ${this.maxLengthPhoneNumber} ${this.$t('errors.residents.diits')}`, maxLength(this.maxLengthPhoneNumber))
        },
        email: {
          required: helpers.withMessage(this.$t('errors.residents.emailRequired'), required),
          email: helpers.withMessage(this.$t('errors.residents.validEmail'), email),
          maxLengthValue: helpers.withMessage(`${this.$t('errors.residents.emailMax')} ${this.maxLengthEmail} ${this.$t('errors.residents.characters')}`, maxLength(this.maxLengthEmail))
        },
        building: {
          required: helpers.withMessage(this.$t('errors.residents.buildingRequired'), required),
          maxLengthValue: helpers.withMessage(`${this.$t('errors.residents.buildingMax')} ${this.maxLengthBuilding} ${this.$t('errors.residents.characters')}`, maxLength(this.maxLengthBuilding)),
          format: helpers.withMessage(this.$t('errors.residents.buildingAlpha'), helper.validateApartmentAndBuildingFormat)
        },
        apartment: {
          required: helpers.withMessage(this.$t('errors.residents.apartmentRequired'), required),
          maxLengthValue: helpers.withMessage(`${this.$t('errors.residents.apartmentMax')} ${this.maxLengthApartment} ${this.$t('errors.residents.characters')}`, maxLength(this.maxLengthApartment)),
          format: helpers.withMessage(this.$t('errors.residents.apartmentAlpha'), helper.validateApartmentAndBuildingFormat)
        }
      }
    }
  },
  computed: {
    ...mapGetters('residential', ['activeResidential']),
    dialog () {
      return this.show
    },
    resident () {
      this.setData()
      if (this.data === {}) {
        return {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          building: '',
          apartment: '',
          isActive: false
        }
      } else {
        const { firstName, lastName, phoneNumber, email, building, apartment, status } = this.data
        return {
          firstName,
          lastName,
          phoneNumber,
          email,
          building,
          apartment,
          isActive: status === this.status.ACTIVE
        }
      }
    },
    countriesCodeInfoToShow () {
      return Object.keys(countryCodes.countryCodes).map(abbrv => ({
        id: abbrv,
        description: `${abbrv.trim()} (+${countryCodes.countryCallCodes[abbrv]})`,
        flag: abbrv.trim()
      }))
    }
  },
  methods: {
    ...mapActions('user', ['createResident', 'updateResident', 'removeResident', 'changeUserRole', 'getUserRole']),
    ...mapActions('loading', ['setLoading']),

    setData () {
      if (this.data === {}) {
        this.clearForm()
      } else {
        const { firstName, lastName, phoneNumber, email, building, apartment, status } = this.data
        this.residentForm = {
          firstName,
          lastName,
          phoneNumber,
          email,
          building,
          apartment,
          isActive: status === this.status.ACTIVE
        }
      }
    },

    async save () {
      if (this.validateForm() === false) {
        return
      }

      this.$store.dispatch('loading/setLoading', true)
      const data = { ...this.residentForm, residentialId: this.activeResidential.residentialId }
      try {
        if (this.isEditing) {
          const id = this.data.id
          data.userResidentialId = this.data.userResidentialId
          data.residentialId = this.activeResidential.residentialId
          await this.updateResident({ id, data })
          this.$swal(this.$t('residents.modified'), '', 'success')
        } else {
          await this.createResident(data)
          this.$swal(this.$t('residents.created'), '', 'success')
        }
        this.close()
      } catch (error) {
        // console.log(error)
        this.$swal(this.$t('errors.tryLater'), '', 'error')
      }
      this.$store.dispatch('loading/setLoading', false)
    },

    validateForm () {
      if (this.isEditing) {
        if (this.residentForm.building === '' || this.residentForm.apartment === '') {
          this.$swal(this.$t('residents.buildingRequired'), '', 'warning')
          return false
        }
      } else {
        this.v$.residentForm.$touch()
        const isValid = !(this.v$.residentForm.$error)
        if (!isValid) {
          return false
        }
      }
      return true
    },

    remove () {
      this.$swal({
        title: `${this.$t('residents.deleteResidentQuestion')} ${this.data.firstName} ${this.data.lastName}?`,
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        confirmButtonColor: '#dc3545',
        cancelButtonText: this.$t('no'),
        cancelButtonColor: '#2186b0'
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.removeResident(this.data.userResidentialId).then(() => {
            this.$swal(`${this.data.firstName} ${this.data.lastName} ${this.$t('residents.deleted')}`, '', 'success')
            this.close()
          }).catch(() => {
            this.$swal(this.$t('errors.tryLater'), '', 'error')
          })
        }
      })
    },

    clearForm () {
      this.residentForm.firstName = ''
      this.residentForm.lastName = ''
      this.residentForm.countryCode = ''
      this.residentForm.phoneCountryCode = ''
      this.residentForm.phoneNumber = ''
      this.residentForm.email = ''
      this.residentForm.building = ''
      this.residentForm.apartment = ''
      this.residentForm.isActive = false
      this.isEditing = false
      this.countryCodeSelected = ''
      this.v$.$reset()
    },

    toggleRole (role, e) {
      const isActive = e.target.checked
      this.setLoading(true)
      const roleId = RolesEnum[role]
      this.changeUserRole({ roleId, residentialId: this.activeResidential.residentialId, add: isActive, userId: this.data.id })
        .then(() => {
          if (role === 'OWNER') {
            this.changeOwnerRole = isActive
          }
          if (role === 'OCCUPIER') {
            this.changeOccupierRole = isActive
          }
          if (role === 'COUNCIL') {
            this.changeCouncilRole = isActive
          }
          if (role === 'COHABITATION') {
            this.changeCohabitationRole = isActive
          }
          if (role === 'TENANT') {
            this.changeTenantRole = isActive
          }
        })
        .catch((err) => {
          const error = new Error(err)
          console.log('Error Actualizando', role)
          if (role === 'OWNER') {
            this.changeOwnerRole = !isActive
          }
          if (role === 'OCCUPIER') {
            this.changeOccupierRole = !isActive
          }
          if (role === 'COUNCIL') {
            this.changeCouncilRole = !isActive
          }
          if (role === 'COHABITATION') {
            this.changeCohabitationRole = !isActive
          }
          if (role === 'TENANT') {
            this.changeCohabitationRole = !isActive
          }
          console.log(error)
          this.$swal(this.$t('errors.somethingHappened'), '', 'error')
        })
        .finally(() => {
          this.setLoading(false)
        })
    },

    getRoles (residentialId, userId) {
      this.getUserRole({ residentialId, userId })
        .then((response) => {
          const userRoles = response.data.map((rol) => rol.role_id)
          this.changeCohabitationRole = userRoles.includes(7)
          this.changeTenantRole = userRoles.includes(11)
          this.changeCouncilRole = userRoles.includes(8)
          this.changeOwnerRole = userRoles.includes(9)
          this.changeOccupierRole = userRoles.includes(10)
        })
        .catch((err) => {
          console.log(err)
          this.$swal(this.$t('errors.somethingHappened'), '', 'error')
        })
        .finally(() => {
          this.setLoading(false)
        })
    },

    close () {
      this.clearForm()
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
  .modal-container {
    background-color: #fff;
    color: black;
    width: 655px;
    border-radius: 20px;
    padding: 20px;
    padding-top: 10px;
    webkit-box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 41%);
    box-shadow: 3px 5px 6px 0px rgb(0 0 0 / 41%);
    z-index: 99;
  }

  .errorMessageValidation {
    width:100%;
    align-self:center;
    /* position: absolute; */
    display: flex;
    justify-content:center;
    /* margin-top: -40px;
    margin-left: -30px */
  }
</style>
